body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
}
@media (max-width: 585px) {
  .prodimg {
    max-height: 13rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Works on Firefox */
/* For Firefox */
* {
  scrollbar-color: #342876 white;
}

/* For Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px; /* Adjust as needed */
  border-radius: 90px !important;
}

*::-webkit-scrollbar-track {
  background-color: white;
}
.drawertext * {
  text-align: initial !important;
}
