.mytable * {
  text-align: center !important;
  justify-content: center !important;
  font-size: 1.2rem !important;
  border-bottom: 0px !important;
}
.mytable {
  margin-bottom: 4rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  border-radius: 2rem !important;
}
.mytable .rdt_Table {
  padding: 2rem 0 !important;
}
.rdt_TableHeadRow {
  color: #5a5a5a;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
}
.subCart {
  border-radius: 4px;
  background-color: #dddddd !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.subCart hr {
  width: 2rem;
  color: #969696;
}
.quanCart {
  color: #342876 !important;
}
.addCart {
  border-radius: 4px;
  background-color: #dddddd !important;
  color: #969696 !important;
}
.calc button,
.calc span {
  border-radius: 4px !important;
  font-size: 2rem !important;
}
.product-info {
  display: flex;
  justify-content: space-between !important;
  width: 400px !important;
}
.dele-icon {
  background-color: #342876;
  padding: 5px 5px 2px 5px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
}
