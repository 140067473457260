.cardcheck {
  width: 100%;
  height: 25rem !important;
  background-color: white !important;
  padding: 3rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.detailsinfo {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.infor {
  width: 100% !important;
  color: #5a5a5a;
  font-weight: 500;
}
.infor * {
  font-weight: 500;
}
.checkout {
  background-color: #342876 !important;
  border-radius: 10px !important;
  color: white !important;
  margin-bottom: 0.7rem !important;
  text-transform: capitalize !important;
  font-weight: 500;
}
.shopping {
  color: #565656;
  text-transform: capitalize !important;
  border: #342876 1px solid !important;
  border-radius: 10px !important;
  font-weight: 500;
}
