.navHead a {
  text-decoration: none !important;
  color: black !important;
  margin: 0px 2rem !important;
  text-transform: uppercase !important;
  font-size: 1.1rem !important;
}
.active1 {
  border-bottom: 2px solid #342876;
}
@media (max-width: 700px) {
  .navHead {
    flex-direction: column !important;
    justify-content: space-between !important;
    padding: 10px 0px;
    align-items: center;
    min-height: 14rem !important;
  }
}
@media (max-width: 589px) {
  .navHead {
    display: none !important;
  }
}
@media (min-width: 700px) {
  .navHead {
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
    z-index: 1200 !important;
  }
}
