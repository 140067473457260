.login {
  width: 39.5rem;
  min-height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  border-radius: 1.2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 600px) {
  .login {
    width: 29rem;
  }
}
@media (max-width: 500px) {
  .login {
    width: 22rem;
    padding: 2rem;
  }
}
.loginscreen {
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}
.loginInputs {
  width: 24rem;
  min-height: 20rem;
}
.loginInputs input {
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}
