.navbarCus .css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  background-color: #f1eeff !important;
}

.navbarCus * {
  color: #141414 !important;
}
.sign {
  background-color: white !important;
  margin-left: 13px !important;
  border-radius: 20px !important;
  padding: 7px 17px 7px 17px !important;
}
.textf .MuiInputBase-formControl {
  height: 2.6rem !important;
  width: 36.18rem !important;
}
.field {
  background-color: white !important;
  border-radius: 20px;
}
.field .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.css-igs3ac {
  border: none !important;
}
@media (max-width: 1100px) {
  .textf .MuiInputBase-formControl {
    width: 13.18rem !important;
  }
}
@media (max-width: 767px) {
  .textf .MuiInputBase-formControl {
    width: 9rem !important;
  }
}
.welcom {
  color: blue !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}
.dropbtn {
  color: white;
  background: transparent !important;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.listItems * {
  font-size: 1.4rem !important;
}
