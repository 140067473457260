#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.swiper-pagination {
  bottom: -10px !important;
  position: initial !important;
}
.swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiperhome .swiper-button-next {
  top: 30% !important;
}

.swiperhome .swiper-button-prev {
  top: 30% !important;
}
.fieeeld fieldset {
  border: 2px solid #342876 !important;
  border-radius: 20px !important;
}
.fieeeld {
  animation-name: none !important;
  transform: none !important;
  transition: none !important;
}
