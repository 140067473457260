.register {
  width: 39.5rem;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  border-radius: 1.2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.4rem 0;
}
.registerscreen {
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}
.registerInputs {
  width: 24rem;
  min-height: 400px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}
