.footer {
  min-height: 29.3rem;
  background-color: #f1eeff;
  padding: 0px 3rem;
  margin-top: 5rem !important;
}
.row-cont {
  /* align-items: center;
  flex-direction: column; */
}
.row-cont ul {
  list-style: none;
  text-transform: uppercase !important;
  padding: 0;
}
.row-cont ul li {
  margin-bottom: 1rem;
  color: #565656;
  font-size: 1.1rem;
}
.social .socials img {
  width: 2.4rem !important;
  height: 2.4rem;
  margin: 0px 0.2rem;
}
@media (max-width: 468px) {
  .social .socials img {
    width: 2rem !important;
    height: 2rem;
  }
  .checks img {
    width: 13rem !important;
  }
}
