.active {
  background-color: #342876;
  cursor: pointer;
  padding: 0 0.6rem;
  color: white;
  width: 100% !important;
}
.notactive {
  background-color: #d9d9d9;
  border-radius: 1.8rem;
  padding: 1rem;
  width: fit-content !important;
  cursor: pointer;
}
@media (max-width: 968px) {
  .category {
    /* width: 33.3333% !important; */
  }
}
@media (min-width: 968px) {
}
@media (max-width: 700px) {
}
.swip .swiper-pagination {
  position: absolute !important;
  bottom: 20px !important;
}
.swip .swiper-button-prev {
  left: 0px !important;
}
