.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@media (max-width: 967px) {
  html {
    font-size: 11.5px !important;
  }
}
p {
  margin: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.flex-cen {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex-bet {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.flex-col {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.flex-col-start {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
}

.flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.swiper-button-prev,
.swiper-button-next {
  background-color: #342876 !important;
  border-radius: 50%;
  padding: 2rem;

  color: white !important;
}
