.css-5gg2z1-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.MuiPaper-elevation1 {
  background-color: transparent !important;
  box-shadow: none !important;
}
.css-46bh2p-MuiCardContent-root {
  padding: 2px !important;
}
.calc button {
  border-radius: 0px !important;
  height: 100% !important;
  width: 25% !important;
  font-size: 30px;
  color: #342876;
}
.add1 * {
  border: transparent !important;
}

.sub * {
  width: 30px !important;
  color: black !important;
}
.add1 * {
  font-size: 30px;
}
.sub,
.del,
.quan {
  border-right: 1px solid #342876 !important;
}
.cardhov {
  transition: transform 0.2s !important;
  transform-origin: center !important;
}

.cardhov:hover {
  transform: scale(1.04);
  transition: transform 0.13s !important;
}
